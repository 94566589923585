module.exports = {
	nonfood: {
		'Ingredients & Contents': {
			id: 'ingredient-tab',
			name: 'Ingredients & Contents',
			icon: 'fa fa-list',
		},
		'Healthy, Safety & Environment': {
			id: 'healthy-safety tab',
			name: 'Health, Safety & Environment',
			icon: 'fa fa-exclamation-triangle',
		},
		'Usage & Handling': { name: 'Usage & Handling', icon: 'fa fa-hand-o-up' },
		'Features & Benefits': {
			id: 'features-benefits tab',
			name: 'About this Product',
			icon: 'fa fa-info-circle',
		},
		'Company, Brand & Sustainability': {
			id: 'company-brand tab',
			name: 'Company, Brand & Sustainability',
			icon: 'fa fa-share-square',
		},
	},
	food: {
		'Ingredients & Contents': {
			id: 'ingredients tab',
			name: 'Ingredients & Contents',
			icon: 'fa fa-list',
		},
		'Health & Safety': {
			id: 'health-safety tab',
			name: 'Health & Safety',
			icon: 'fa fa-exclamation-triangle',
		},
		'Usage & Handling': { name: 'Usage & Handling', icon: 'fa fa-hand-o-up' },
		'Other Information': {
			id: 'other-information tab',
			name: 'Other Information',
			icon: 'fa fa-info-circle',
		},
		'Company/ Brand': {
			id: 'company-brand tab',
			name: 'Company/ Brand',
			icon: 'fa fa-share-square',
		},
	},
};
